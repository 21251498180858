import { cn } from "@nephroflow/design-system/styling/utils";
import * as React from "react";

import { defineMessages, isMessageDescriptor, MessageDescriptor, useIntl } from "~/intl";

import { IconButton } from "./button";
import { CloseIcon, DeleteIcon, DoneIcon } from "./icon";

export function DeleteOverlayModal({
  onConfirm,
  confirmationMessage,
}: {
  onConfirm: () => void;
  confirmationMessage?: string | MessageDescriptor;
}) {
  const [showModal, setShowModal] = React.useState<boolean | null>(null);
  const nodeRef = React.useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();

  const message = isMessageDescriptor(confirmationMessage)
    ? formatMessage(confirmationMessage)
    : formatMessage(t.deleteConfirmationMessage);

  const handleConfirmAction = () => {
    onConfirm();
    setShowModal(false);
  };

  const handleCancelAction = () => {
    setShowModal(false);
  };

  React.useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (nodeRef.current && !nodeRef.current.contains(event.target as Node)) {
        setShowModal(false);
      }
    };

    if (showModal) {
      window.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showModal]);

  return (
    <div className="-my-2">
      <IconButton
        className="ml-4 flex items-center justify-center"
        importance="tertiary"
        impact="neutral"
        title={formatMessage(t.deleteTitle)}
        icon={<DeleteIcon className="text-blue" />}
        onClick={() => setShowModal(true)}
      />

      <div
        ref={nodeRef}
        className={cn("right-0 top-0 flex hidden h-full items-center justify-end gap-2 bg-red-20 px-5", {
          "absolute flex w-[400px] duration-200 ease-in animate-in fade-in slide-in-from-right": showModal,
          "duration-200 ease-out animate-out fade-out slide-out-to-right": !showModal && showModal !== null,
        })}
      >
        {showModal ? (
          <>
            <span>{message}</span>
            <IconButton
              onClick={handleConfirmAction}
              title={formatMessage(t.confirmTitle)}
              impact="negative"
              importance="primary"
              icon={<DoneIcon />}
            />
            <IconButton
              onClick={handleCancelAction}
              title={formatMessage(t.cancelTitle)}
              impact="neutral"
              importance="secondary"
              icon={<CloseIcon />}
            />
          </>
        ) : null}
      </div>
    </div>
  );
}

const t = defineMessages({
  confirmTitle: {
    id: "delete_overlay_modal_confirm_title",
    defaultMessage: "Confirm",
  },
  cancelTitle: {
    id: "delete_overlay_modal_cancel_title",
    defaultMessage: "Cancel",
  },
  deleteTitle: {
    id: "delete_overlay_modal_delete_title",
    defaultMessage: "Delete",
  },
  deleteConfirmationMessage: {
    id: "delete_overlay_modal_confirmation_message",
    defaultMessage: "Are you sure?",
  },
});
